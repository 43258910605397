<template>
  <v-card class="elevation-2">
    <v-dialog v-model="watchedRecordsDialog" max-width="700px">
      <v-card>
        <v-card-title class="main-red-bg white--text">
          <h5>Student Watched Time</h5>
          <v-btn
            absolute
            right
            icon
            color="white"
            @click="watchedRecordsDialog = false"
          >
            <v-icon size="20">mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text
          class="d-sm-flex justify-sm-start justify-sm-space-between align-center py-2 mb-2"
        >
          <v-select
            :items="watchedSections"
            class="d-block text-center"
            label="Section"
            hide-details
          ></v-select>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="searchRecord"
            class="d-block text-center"
            label="Search"
            hide-details
          ></v-text-field>
        </v-card-text>
        <v-card-text>
          <v-data-table
            :search="searchRecord"
            :headers="watchedRecordHeaders"
            :items="watchedRecords"
            :loading="watchedRecordsLoading"
            :footer-props="{
              itemsPerPageOptions: watchedPerPage,
            }"
          >
            <template v-slot:[`item.watched`]="{ item }">
              <v-chip outlined label color="info">{{
                convertSeconds(item.watched)
              }}</v-chip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card-title>
      <h2 class="mb-2">{{ lesson.title }}</h2>
    </v-card-title>
    <v-card-subtitle>
      <p>
        {{ lesson.module }}
      </p>
      <h3>Teacher : {{ teacherName }}</h3>
    </v-card-subtitle>
    <div v-if="lesson.file">
      <v-sheet v-if="fileLoading" color="grey lighten-4" class="pa-3">
        <v-skeleton-loader
          class="mx-auto"
          width="100%"
          type="image"
        ></v-skeleton-loader>
      </v-sheet>
      <div class="mx-4" v-else>
        <video
          @durationchange="videoReady"
          :src="dataUrl"
          controls
          width="100%"
          v-if="fileType === 'video'"
        />
        <v-img
          v-else-if="fileType === 'image'"
          :src="dataUrl"
          contain
          max-width="100%"
        ></v-img>
        <iframe
          v-else-if="fileType === 'pdf'"
          :src="dataUrl"
          width="100%"
          height="500px"
        />
        <div class="d-inline-flex align-center" v-else>
          <v-img
            v-if="fileType === 'ppt'"
            src="@/assets/ppt-icon.png"
            width="85"
          ></v-img>
          <v-img
            v-if="fileType === 'docs'"
            src="@/assets/word-icon.png"
            width="85"
          ></v-img>
          <v-img
            v-if="fileType === 'xls'"
            src="@/assets/excel-icon.png"
            width="85"
          ></v-img>
          <div class="ml-2" v-if="file">
            <h5 class="ml-3">{{ file.name }}</h5>
            <v-card-actions>
              <v-btn
                @click="downloadFile(dataUrl, file.name)"
                :loading="downloading"
                small
                color="success"
              >
                <v-icon>mdi-download</v-icon>
                Download
              </v-btn>
            </v-card-actions>
          </div>
        </div>
      </div>
    </div>
    <v-card-text
      class="d-sm-flex justify-sm-start justify-sm-space-between align-center"
    >
      <a
        v-if="watchedRecords.length > 0"
        class="text--primary d-block text-right"
        @click="watchedRecordsDialog = true"
      >
        {{ watchedRecordsCount }}
        <v-icon small right>mdi-account-group</v-icon>
      </a>
      <v-spacer v-else></v-spacer>
      <a
        class="text--primary d-block text-right"
        @click="toggleComments(showComments)"
      >
        {{ commentsCount }}
      </a>
    </v-card-text>
    <div v-if="showComments">
      <v-divider class="mx-2"></v-divider>
      <div
        v-if="totalComments > 3"
        class="d-sm-flex justify-sm-start align-sm-center justify-sm-space-between flex-sm-row-reverse"
      >
        <v-card-actions class="d-flex justify-end align-center">
          <v-select
            style="max-width: 180px"
            flat
            solo
            dense
            hide-details
            :items="filterComments"
            :value="filterBy"
            @change="changeFilter($event)"
          ></v-select>
        </v-card-actions>
        <v-card-actions>
          <v-btn
            v-if="commentsOffset > 0"
            text
            plain
            :ripple="false"
            small
            @click="viewPreviousComments(commentsOffset)"
            >View Previous Comments</v-btn
          >
        </v-card-actions>
      </div>
      <v-card-text v-if="totalComments > 0">
        <div v-for="(comment, index) in comments" :key="index">
          <div v-if="index >= commentsOffset && index < commentsShown">
            <v-card elevation="0" class="grey lighten-3">
              <v-card-text>
                <div class="d-flex justify-space-between">
                  <div>
                    <h3>{{ sanitizeFullName(comment.user) }}</h3>
                    <sup>{{
                      sanitizeSection(comment.user, comment.section)
                    }}</sup>
                  </div>
                  <div
                    class="d-flex justify-end mr-n3"
                    v-if="comment.user.id !== lesson.teacherId && isTeacher"
                  >
                    <v-menu max-width="200px" offset-y v-if="comment.show">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          color="success"
                          :loading="loadingApproval == comment.id"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon left> mdi-check-circle </v-icon>
                          Approved
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-text
                          >This comment will no longer show to all
                          students.</v-card-text
                        >
                        <v-divider></v-divider>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="info"
                            small
                            @click="approveComment(comment.id, !comment.show)"
                          >
                            Yes
                          </v-btn>
                          <v-btn color="error" small> Cancel </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-menu>
                    <v-btn
                      v-else
                      small
                      color="grey"
                      :loading="loadingApproval == comment.id"
                      @click="approveComment(comment.id, !comment.show)"
                    >
                      <v-icon left> mdi-check-circle </v-icon>
                      Approve
                    </v-btn>
                    <v-tooltip :open-on-hover="false" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="mx-n4 mt-n1"
                          top
                          plain
                          text
                          v-bind="attrs"
                          v-on="on"
                          :ripple="false"
                        >
                          <v-icon small> mdi-help-circle-outline </v-icon>
                        </v-btn>
                      </template>
                      <span
                        >Approved comments will be shown to all students</span
                      >
                    </v-tooltip>
                  </div>
                </div>
                <div class="text--primary">
                  {{ comment.comment }}
                </div>
              </v-card-text>
            </v-card>
            <v-btn
              text
              small
              plain
              :ripple="false"
              @click="toggleReplyTo(comment.id)"
            >
              {{ countReplies(comment) }}
            </v-btn>
            <div class="ml-4" v-if="replyingTo(comment.id)">
              <div v-if="comment.replies.length > 0">
                <v-card
                  elevation="0"
                  class="grey lighten-3 mb-4"
                  v-for="(reply, index) in comment.replies"
                  :key="index"
                >
                  <v-card-text>
                    <div class="d-flex justify-space-between">
                      <div>
                        <h3>{{ sanitizeFullName(reply.user) }}</h3>
                        <sup>{{
                          sanitizeSection(reply.user, reply.section)
                        }}</sup>
                      </div>
                    </div>
                    <div class="text--primary">
                      {{ reply.comment }}
                    </div>
                  </v-card-text>
                </v-card>
              </div>
              <div class="mb-4">
                <v-textarea
                  :value="commentData[comment.id]"
                  @input="inputComment(comment.id, $event)"
                  @keydown.enter.prevent
                  @keydown.enter.exact="
                    postComment(lessonId, commentData[comment.id], comment.id)
                  "
                  solo
                  filled
                  :label="
                    currentUser.id == comment.user.id
                      ? 'Write a Reply...'
                      : `Reply to ${sanitizeFullName(comment.user)}`
                  "
                  background-color="grey lighten-3"
                  color="grey darken-2"
                  auto-grow
                  row-height="10"
                  hide-details
                  ><v-icon class="mx-1" slot="append"></v-icon
                ></v-textarea>
                <v-btn
                  @click="
                    postComment(lessonId, commentData[comment.id], comment.id)
                  "
                  class="mr-n2 mt-n8"
                  :ripple="false"
                  absolute
                  right
                  small
                  plain
                  text
                  :disabled="!commentData[comment.id]"
                >
                  <v-icon small color="info"> mdi-send </v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-text>
        <v-textarea
          :value="commentData['comment']"
          @input="inputComment('comment', $event)"
          @keydown.enter.prevent
          @keydown.enter.exact="
            postComment(lessonId, commentData['comment'], 'comment')
          "
          solo
          filled
          label="Write a comment..."
          background-color="grey lighten-3"
          color="grey darken-2"
          auto-grow
          row-height="10"
          hide-details
          ><v-icon class="mx-1" slot="append"></v-icon
        ></v-textarea>
        <v-btn
          @click="postComment(lessonId, commentData['comment'], 'comment')"
          class="mr-n3 mt-n8"
          :ripple="false"
          absolute
          right
          plain
          text
          :disabled="!commentData['comment']"
        >
          <v-icon small color="info"> mdi-send </v-icon>
        </v-btn>
      </v-card-text>
      <v-card-actions>
        <v-btn
          text
          plain
          small
          :ripple="false"
          v-if="commentsShown < totalComments"
          @click="viewMoreComments(commentsShown, totalComments)"
          >View More Comments</v-btn
        >
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import mimetypes from "@/lib/utilities/mimetypes";
import _ from "lodash";
//import io from "socket.io-client";
export default {
  name: "LessonPage",
  metaInfo: {
    title: "Lesson Page",
  },
  data() {
    return {
      //socket: {},
      mimetypes: mimetypes,
      teacherName: "",
      file: null,
      fileType: "",
      fileLoading: false,
      downloading: false,
      watchedPerPage: [5, 10, 25, 50],
      watchedRecordsLoading: false,
      watchedRecordsDialog: false,
      searchRecord: "",
      filterBySection: "",
      dataUrl: "",
      replyTo: [],
      filterComments: [
        { text: "Most Recent", value: 0 },
        { text: "All Comments", value: 1 },
      ],
      filterBy: 0,
      commentsOffset: 0,
      commentsShown: 3,
      showComments: false,
      loadingApproval: "",
      commentData: {
        comment: "",
      },
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters['authModule/getUser'];
    },
    lessonId() {
      return this.$route.params.id;
    },
    ...mapState({
      lesson: (state) => state.lessonsModule.lesson,
      comments: (state) => state.lessonsModule.comments,
      watchedRecords: (state) => state.lessonsModule.watchedRecords,
    }),
    watchedRecordsCount() {
      let watchedRecordsCount = this.watchedRecords.length;
      return watchedRecordsCount > 1
        ? `${watchedRecordsCount} students watched this`
        : `${watchedRecordsCount} student watched this`;
    },
    watchedRecordHeaders() {
      return [
        {
          text: "Student",
          align: "start",
          value: "studentName",
        },
        {
          text: "Section",
          align: "center",
          sortable: true,
          value: "section",
          filter: (value) => {
            if (!this.filterBySection) {
              return true;
            }
            return this.filterBySection == value;
          },
        },
        {
          text: "Watched Time",
          align: "center",
          sortable: true,
          value: "watched",
        },
      ];
    },
    watchedSections() {
      return this.watchedRecords.map((watched) => watched.section);
    },
    commentsCount() {
      //count total number of comments along with their replies
      let initialCount = this.comments.length,
        commentsCount = this.comments.reduce(
          (prev, next) => prev + next.replies.length,
          initialCount
        );
      return commentsCount > 0
        ? `${commentsCount} ${commentsCount == 1 ? "Comment" : "Comments"}`
        : "Comment";
    },
    totalComments() {
      return this.comments ? this.comments.length : 0;
    },
    isTeacher() {
      return this.lesson.teacherId === this.currentUser.id;
    },
  },
  // created() {
  //   this.socket = io(process.env.VUE_APP_ASL_BASE_URL);
  // },
  async mounted() {
    const lessonId = this.lessonId,
      isTeacher = this.isTeacher;
    this.$store.commit("lessonsModule/clearWatchedRecords");
    //set selected lesson by route params (lessonId)
    this.$store.dispatch("lessonsModule/filterLessonById", lessonId);
    //check for file
    if (this.lesson.file) {
      //fetch file
      await this.getFileData(this.lesson.file);
    }
    //get comments
    await this.$store.dispatch("lessonsModule/getLessonComments", lessonId);
    //set teacher details
    const teacher = this.lesson?.teacher;
    if (teacher) {
      let middleName = teacher.middle_name ? teacher.middle_name : "";
      this.teacherName =
        teacher.first_name + " " + middleName + " " + teacher.last_name;
    }

    let commentsCount = this.totalComments;
    if (commentsCount > 0) {
      //set commentData here
      this.comments.map((comment) => {
        this.commentData = {
          ...this.commentData,
          [comment.id]: "",
        };
      });
      //set offset if comments length is greater than 3 (3 as the total number of shown comments initially)
      if (commentsCount > 3) {
        this.setCommentsOffset(commentsCount);
      }
    }

    //if teacher listen for new watched records
    //if (isTeacher && this.fileType === "video") {
      //listen for new watched record
      // this.socket.on(`watched:${this.lesson.file}`, async () => {
      //   await this.getWatchedRecords(this.lessonId);
      // });
      //listen for update of watched record
      // this.socket.on(
      //   `watchedUpdate:${this.lesson.file}`,
      //   async (watchedRecord) => {
      //     this.$store.commit(
      //       "lessonsModule/updateWatchedRecords",
      //       watchedRecord
      //     );
      //   }
      // );
    //}

    //listen to approved comments to this lesson
    //this.socket.on(`approved:${lessonId}`, async () => {
      //fetch comments again to display approved comments to students
      //await this.$store.dispatch("lessonsModule/getLessonComments", lessonId);
      //set comment data as fresh
    //   let commentData = {
    //     comment: "",
    //   };

    //   this.comments.map((comment) => {
    //     this.commentData = {
    //       ...commentData,
    //       [comment.id]: "",
    //     };
    //   });
    // });

    //listen to new comments to this lesson
    //this.socket.on(`comment:${lessonId}`, (comment) => {
      //const isUser = comment.userId == this.currentUser.id ? true : false;

      //if (isTeacher || isUser || comment.show) {
        //add to comment data
        //this.commentData = { ...this.commentData, [comment.id]: "" };
        //do not show every now and then when comment is not from user
        //let commentsShown = this.commentsShown;
        //if (isUser && this.totalComments > 3) {
          //check if the next comment to be shown is for user else keep increasing
      //     do {
      //       let commentUserId = this.comments[commentsShown].userId;
      //       if (commentUserId == this.currentUser.id) {
      //         commentsShown = commentsShown + 1;
      //         break;
      //       }
      //       commentsShown = commentsShown + 1;
      //     } while (commentsShown < this.totalComments);
      //     this.commentsShown = commentsShown++;
      //   }
      // }

      //filter comment is not from user to avoid dupe && either user is teacher or comment is public
      //if (!isUser && (isTeacher || comment.show)) {
        //add comment
        //this.$store.commit("lessonsModule/addComment", comment);
      //}
    //});

    //listen to new replies to this lesson
    // this.socket.on(`reply:${lessonId}`, (reply) => {
    //   const commentById = (comment) =>
    //     comment.id === reply.commentId && comment.commentId === null;
    //   let comment = this.comments.find(commentById);

    //   const isUser = comment.userId == this.currentUser.id ? true : false;
    //   const isUserReply = reply.userId == this.currentUser.id ? true : false;

      //filter reply is not from user to avoid dupe && either user is teacher or comment is public or comment is from user
      //if (!isUserReply && (isTeacher || isUser || comment.show)) {
        //add reply
    //     this.$store.commit("lessonsModule/addReply", reply);
    //   }
    // });
  },
  methods: {
    convertSeconds(seconds) {
      var hours = Math.floor(seconds / 3600);
      var minutes = Math.floor((seconds - hours * 3600) / 60);
      var seconds = seconds - hours * 3600 - minutes * 60;

      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      return hours + ":" + minutes + ":" + seconds;
    },
    videoReady(video) {
      //check if user has watched the video before
      const userLeftOff = this.lesson.videoWatched?.currentTime ?? 0,
        userWatched = this.lesson.videoWatched?.watched ?? 0,
        duration = Math.trunc(video.target.duration);

      if (userLeftOff < duration) {
        video.target.currentTime = userLeftOff;
      }

      video.target.onpause = () => {
        //check time range count on each pause
        let timeRangeCount = video.target.played.length,
          timeRange = video.target.played,
          currentTime = Math.trunc(video.target.currentTime),
          seeking = video.target.seeking,
          watched = userWatched;

        //only update if video is not yet finished by user
        if (userWatched < duration) {
          if (timeRangeCount > 1) {
            for (let index = 0; index < timeRangeCount; index++) {
              watched += timeRange.end(index) - timeRange.start(index);
            }
          } else {
            watched += timeRange.end(0) - timeRange.start(0);
          }

          if (watched > duration) {
            watched = duration;
          }
        }

        //only post if video is not seeking to avoid multiple event fire to server when seeking
        if (!seeking) {
          //add new or update watch entry to user
          let payload = {
            currentTime: currentTime,
            watched: watched,
            videoFile: this.lesson.file,
            newEntry: true,
            isTeacher: this.isTeacher,
          };

          if (this.lesson.videoWatched) {
            //update
            payload.newEntry = false;
            payload = { ...payload, id: this.lesson.videoWatched.id };

            this.$store.dispatch("lessonsModule/upsertVideoWatched", payload);
          } else {
            //insert new
            this.$store.dispatch("lessonsModule/upsertVideoWatched", payload);
          }
        }
      };
    },
    checkCommentsShown() {
      const totalComments = this.totalComments,
        commentsShown = this.commentsShown,
        commentsOffset = this.commentsOffset;

      if (totalComments == commentsShown && commentsOffset == 0) {
        //set filter to all comments if all comments are shown
        this.filterBy = 1;
      }
    },
    changeFilter(filter) {
      const totalComments = this.totalComments;
      if (filter) {
        this.commentsShown = totalComments;
        this.commentsOffset = 0;
        this.checkCommentsShown();
      } else {
        this.setCommentsOffset(totalComments);
      }
    },
    setCommentsOffset(totalComments) {
      //set offset based on total comments by subtracting 3 (being 1 as the lowest possible index)
      let offset = totalComments - 3;
      this.commentsOffset = offset;
      this.commentsShown = totalComments;
    },
    viewPreviousComments(commentsOffset) {
      //less three for each trigger until offset becomes 0
      let diff = commentsOffset - 3;
      if (diff > 0) {
        this.commentsOffset = diff;
      } else {
        this.commentsOffset = 0;
        this.checkCommentsShown();
      }
    },
    viewMoreComments(commentsShown, totalComments) {
      //add three for each trigger until all comments are shown
      let sum = commentsShown + 3;
      if (sum < totalComments) {
        this.commentsShown = sum;
      } else {
        this.commentsShown = totalComments;
        this.checkCommentsShown();
      }
    },
    async approveComment(commentId, status) {
      this.loadingApproval = commentId;
      const payload = {
        commentId,
        status,
        lessonId: this.lessonId,
      };
      await this.$store.dispatch("lessonsModule/approveComment", payload);
      this.loadingApproval = "";
    },
    toggleComments(showComments) {
      if (!showComments) {
        this.showComments = true;
      } else {
        this.showComments = false;
      }
    },
    async postComment(lessonId, comment, key) {
      if (comment) {
        let payload = {
          lessonId,
          comment,
          key,
        };
        this.commentData[key] = "";
        await this.$store.dispatch("lessonsModule/postComment", payload);
      }
    },
    inputComment(key, value) {
      this.commentData[key] = value;
    },
    toggleReplyTo(commentId) {
      if (!this.replyTo) {
        return this.replyTo.push(commentId);
      } else {
        return this.replyTo.includes(commentId)
          ? this.replyTo.splice(this.replyTo.indexOf(commentId), 1)
          : this.replyTo.push(commentId);
      }
    },
    replyingTo(commentId) {
      return this.replyTo.includes(commentId) ? true : false;
    },
    countReplies(comment) {
      let repliesCount = comment.replies.length;
      return repliesCount > 0
        ? `${repliesCount} ${repliesCount == 1 ? "Reply" : "Replies"}`
        : "Reply";
    },
    sanitizeFullName(user) {
      let middleName = user.middleName || "";
      return user.firstName + " " + middleName + " " + user.lastName;
    },
    sanitizeSection(user, section) {
      if (!section) {
        return this.lesson.teacherId === user.id ? "Teacher" : "";
      }
      return section.gradeLevel + " - " + section.name;
    },
    async getFileData(fileName) {
      this.fileLoading = true;
      //get filemeta from firebase
      const fileMeta = await this.$store.dispatch(
        "lessonsModule/getFileMeta",
        fileName
      );

      if (fileMeta) {
        this.file = {
          name: fileMeta.name.split("-")[2],
          size: fileMeta.size,
        };
        //check file type for preview
        this.fileType = this.checkFile(fileMeta.contentType);
        this.dataUrl = await this.$store.dispatch(
          "lessonsModule/getFileUrl",
          fileName
        );
        //check for watched record if lesson's file type is video, for teacher only
        if (this.isTeacher && this.fileType === "video") {
          await this.getWatchedRecords(this.lessonId);
        }
      }

      this.fileLoading = false;
    },
    async getWatchedRecords(lessonId) {
      this.watchedRecordsLoading = true;
      await this.$store.dispatch("lessonsModule/getWatchedRecords", lessonId);
      this.watchedRecordsLoading = false;
    },
    checkFile(type) {
      //check file upload for mimetypes
      if (mimetypes.video.includes(type)) {
        return "video";
      } else if (mimetypes.image.includes(type)) {
        return "image";
      } else if (mimetypes.pdf.includes(type)) {
        return "pdf";
      } else if (mimetypes.xls.includes(type)) {
        return "xls";
      } else if (mimetypes.ppt.includes(type)) {
        return "ppt";
      } else if (mimetypes.docs.includes(type)) {
        return "docs";
      } else {
        return "noPreviewAvailable";
      }
    },
    downloadFile(fileUrl, fileName) {
      //create an xml http request and allow cors in firebase to download
      const self = this;
      self.downloading = true;
      var xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.open("GET", fileUrl, true);
      xhr.send();
      xhr.onreadystatechange = function () {
        if (this.readyState == 4 && this.status == 200) {
          const downloadUrl = window.URL.createObjectURL(this.response);
          const anchor = document.createElement("a");
          anchor.href = downloadUrl;
          anchor.download = fileName;
          document.body.appendChild(anchor);
          anchor.click();
          self.downloading = false;
        }
      };
    },
  },
};
</script>

<style></style>
